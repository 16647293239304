<template>
    <b-overlay :show="dataLoaded">
        <b-card class="w-100">
            <b-alert class="mt-1 p-1 text-center" show variant="warning" v-if="hasBeenDeleted">Po brisanju je potrebnih nekaj sekund, da je brisanje izvedeno. Če je oglas po brisanju še vedno viden, ponovno naložite stran.</b-alert>
            <Table ref="table" class="mt-1" :fields="fields" route="/api/management/v1/es/c2c" :fromElastic="true">

                <template #cell(main_photo)="row">
                    <b-img v-if="row.item.main_photo !== ''" fluid  :src="'/api/management/v1/image/thumbnail_' + row.item.main_photo" style="width: 100%; max-height: 70px; object-fit: contain;" alt="Image"/>
                </template>
                <template #cell(condition)="row">
                    {{ getConditionText(row.item.condition) }}
                </template>
                <template #cell(listing_type)="row">
                    {{ getListingTypeText(row.item.listing_type) }}
                </template>
                <template #cell(date_posted)="row">
                    {{moment(row.item.date_posted).format('DD.MM.YYYY')}}
                </template>
                <template #cell(expires)="row">
                    <template v-if="row.item.expires === '0001-01-01T00:00:00Z'">
                        /
                    </template>
                    <template v-else>
                        {{moment(row.item.expires).format('DD.MM.YYYY')}}
                    </template>
                </template>
                <template #cell(approved)="row">
                    <b-badge v-if="row.item.approved" variant="light-success">
                        Da
                    </b-badge>
                    <b-badge v-else variant="light-danger">
                        Ne
                    </b-badge>
                </template>
                <template #cell(approved_at)="row">
                    <span v-if="row.item.approved">
                        {{moment(row.item.approved_at).format('DD.MM.YYYY')}}
                    </span>
                    <span v-else>
                        /
                    </span>
                </template>
                <template #cell(actions)="row" v-if="$hasPermissions($permissions.EditClassifiedAd)">
                    <!-- <div class="d-flex justify-content-center"> -->
                 
                    <div class="button-grid">

                        <!-- <b-button variant="primary" :href="'/oglasi/' + row.item.id" target="_blank"><fa icon="newspaper"/></b-button> -->

                        <b-button v-if="!row.item.approved"  variant="success" @click="approveClassifiedAd(row.item.id)"><fa icon="check"/></b-button>
         
                        <b-button variant="warning" @click="$router.push({name: 'edit-classified-ad', params: {ad_id: row.item.id}})"><fa icon="edit"/></b-button>
        
                        <b-button variant="danger" @click="deleteClassifiedAd(row.item.id)"><fa icon="trash"/></b-button>
                    </div>
          
           
                    <!-- </div> -->
                </template>
            </Table>
        </b-card>
    </b-overlay>
</template>
<script>
    import Table from '@/views/Components/Table'
    import {BBadge, BButton, BCard, BImg, BOverlay} from 'bootstrap-vue'
    export default {
        components: {
            Table,
            BOverlay,
            BCard,
            BButton,
            BImg,
            BBadge
        },
        data() {
            return {
                dataLoaded: false,
                items: [],
                fields: [
                    { key: 'main_photo', label: 'Slika', sortable: true, class: 'text-center' },
                    { key: 'title', label: 'Naslov', sortable: true, class: 'text-center title-table-data' },
                    { key: 'condition',
                      label: 'Stanje',
                      sortable: true,
                      class: 'text-center',
                      type: 'select',
                      options: [
                          { value: null, text: '---'},
                          { value: 0, text: 'Novo'},
                          { value: 1, text: 'Rabljeno'},
                          { value: 2, text: 'Poškodovano'}
                      ] },
                    { key: 'listing_type',
                      label: 'Tip',
                      sortable: true,
                      class: 'text-center',
                      type: 'select',
                      options: [
                          { value: null, text: '---'},
                          { value: 0, text: 'Prodam'},
                          { value: 1, text: 'Kupim'},
                          { value: 2, text: 'Zamenjam'},
                          { value: 3, text: 'Oddam'},
                          { value: 4, text: 'Podarim'}
                      ] },
                    { key: 'date_posted', label: 'Datum objave', sortable: true, class: 'text-center', type: 'date' },
                    { key: 'expires', label: 'Datum poteka', sortable: true, class: 'text-center', type: 'date' },
                    { key: 'approved', label: 'Odobreno', sortable: true, class: 'text-center', type: 'bool' },
                    { key: 'approved_at', label: 'Datum odobritve', sortable: true, class: 'text-center', type: 'date' }
                ],
                hasBeenDeleted: false
            }
        },
        methods: {
            getConditionText(condition) {
                if (condition === 0 || condition === '0') return 'Novo'
                else if (condition === 1 || condition === '1') return 'Rabljeno'
                else if (condition === 2 || condition === '2') return 'Poškodovano'
                else return '/'
            },
            getListingTypeText(listing_type) {
                if (listing_type === 0 || listing_type === '0') return 'Prodam'
                else if (listing_type === 1 || listing_type === '1') return 'Kupim'
                else if (listing_type === 2 || listing_type === '2') return 'Zamenjam'
                else if (listing_type === 3 || listing_type === '3') return 'Oddam'
                else if (listing_type === 4 || listing_type === '4') return 'Podarim'
                else return '/'
            },
            approveClassifiedAd(id) {
                const thisIns = this
                thisIns.dataLoaded = true

                this.$http.post(`/api/management/v1/c2c/approve/${id}`)
                    .then(function() {
                        thisIns.$printSuccess('Oglas je potrjen')
                    }).catch(function(error) {
                        thisIns.$printError(error.response.data)
                    }).finally(function() {
                        const index = thisIns.items.findIndex(i => i.id === id)
                        if (index === -1) thisIns.$refs.table.search()
                        else {

                            const currentDate = new Date()

                            // changing approved_at and expires date in frontend, so that table shows updated days
                            // without reloading all data
                            thisIns.items[index].approved = true
                            thisIns.items[index].approved_at = currentDate
                            thisIns.items[index].expires = new Date(currentDate.getTime() + (30 * 24 * 60 * 60 * 1000))
                        }
                        thisIns.dataLoaded = false
                    })
            },
            async deleteClassifiedAd(id) {
                try {
                    const deleteConfirmationResult = await this.$bvModal.msgBoxConfirm('Prosimo potrdite, da želite izbrisati oglas.', {
                        size: 'sm',
                        okTitle: 'Da',
                        cancelTitle: 'Ne',
                        hideHeaderClose: false,
                        okVariant: 'danger',
                        cancelVariant: 'outline-secondary',
                        centered: true
                    })
                    if (!deleteConfirmationResult) return

                    this.dataLoaded = true

                    await this.$http.delete(`/api/management/v1/c2c/${id}`)
                    this.$printSuccess('Oglas je bil izbrisan')
                    this.hasBeenDeleted = true
                    setTimeout(() => {
                        this.hasBeenDeleted = false
                    }, 10_000)

                    setTimeout(() => {
                        this.$refs.table.search()
                    }, 1000)
                } catch (error) {
                    this.$printError(error.response.data)
                } finally {
                    this.dataLoaded = true
                }
            }
        },
        mounted() {
            if (this.$hasPermissions(this.$permissions.EditClassifiedAd)) {
                this.fields.push({ key: 'actions', label: '', class: 'text-center' })
            }
        }
    }
</script>
<style>
.title-table-data {
    word-break: break-all !important;
    min-width: 200px;
}
.button-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 10px;
    row-gap: 10px;
}
</style>